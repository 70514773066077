import React, { useState, useEffect } from "react"

const WorkInfo = props => {
  const navRef = React.useRef(null);
  const barRef = React.useRef(null);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 81);
    });
  }, []);
  
  const onAddClick = (e) => {
    barRef.current.classList.add("work__info-toggle--active");
  };

  const onRemoveClick = (e) => {
    navRef.current.classList.remove("work__info--hidden");
  };

  const onAddClickB = (e) => {
    navRef.current.classList.add("work__info--hidden");
  };

  

  return (
    <div>

      <span  onClick={onAddClick, onRemoveClick}  id={scroll ? "fixed" : "sticky"} className="work__info__bar work__info-toggle color-contrast-higher py-6">Project Information</span>
      <div ref={navRef}class="work__info work__info--hidden px-6  pt-28 flex justify-center">
        <div class="flex flex-col work__info-container lg:flex-row" ref={barRef}>
       
            <section class="work__info__section col">
              <h2  onClick={onAddClickB} class="work__info-toggle toggle-open color-contrast-higher">{props.title}</h2>
              <a class="link-fx-5 flex flex-row items-center mt-8" href={"https://" + props.link} target="_blank" rel="noreferrer" > 
                <img src="../../files/link.svg" alt="Link icon"></img>
                <span class="padding-left-xxs text-nowrap">{props.link} </span>
              </a>
              <div class="work__info__team my-12 lg:my-20">
                <h3 class="color-contrast-higher mb-4">Team</h3>
                <ul>
                  <li>{props.team1}</li>
                  <li class="mt-1">{props.team2}</li>
                  <li class="mt-1">{props.team3}</li>
                  <li class="mt-1">{props.team4}</li>
                </ul>
              </div>
            </section>
            <section class="work__info__section col col--right mb-20">
              <h3 class="color-contrast-higher mb-4">Challenge</h3>
              <p>{props.challenge}</p>
              <h3 class="color-contrast-higher mt-12 lg:mt-20 mb-4">Solution</h3>
              <p class="">{props.solution}</p>
            </section>
          
        </div>
      </div>

    </div>

  );
}

export default WorkInfo