import React from "react"
import { Link } from "gatsby"





const ProjectCard = props => (
  
    <li className={props.classes + " reveal-fx reveal-fx--translate-up mb-8"}>
        <Link className=" card-v9" to={ "/work/" + props.url} style={{
          backgroundImage: `url(${props.cover})`
        }}>
            <div class="card-v9__content padding-md adv-gallery-v3__img-link flex-col items-between">
                <div class="max-width-xxs">
                    <h2 class="color-contrast-higher color-opacity-50% margin-bottom-xxs">{props.title}</h2>
                    <h3 className="color-contrast-medium"id="card-title-2">{props.description}</h3>
                </div>
                <h3 className="position-absolute pb-10 bottom-0 color-contrast-medium">{props.role}</h3>
            </div>

        </Link>
    </li>
);

export default ProjectCard