import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkInfo from "../../components/work-info"
import { StaticImage } from "gatsby-plugin-image"
import HomeDesktop from "../../images/projects/versed/home--desktop.mp4"
import ProjectCard from "../../components/project-card"
import RelevantSkin from "../../images/projects/relevant-skin/cover.jpg"
import IsaLazo from "../../images/projects/isa-lazo/cover.jpg"

const aurisPage = () => (
  <Layout>
    <SEO title="Versed Skin" />
    <WorkInfo 
      title="Versed Skin"
      link="versedskin.com"
      team1="Bailey Latimer"
      team2="Jeremy Holsted"
      team3="Noel Reinhold"
      team4="Heather Rieder"
      challenge="Versed Skin sought to revolutionize the natural skincare industry with all-natural, vegan ingredients for cleansers, moisturizers, serums, masks. As a native DTC brand, they wanted their site to inform, captivate, and convert."
      solution="Working with a small team of designers and developers, we did just that. Our solution provides inclusive, relatable assets and copy. To inform, we built a quiz that helps users discover which products work best for them. To convert, we implemented a subscription model using ReCharge that encourages repeat purchases."/>

    <video class="mb-6"
      loop
      muted
      autoPlay>
      <source src={HomeDesktop} type="video/mp4" />
    </video>

      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/versed/browser.jpg" alt="Versed Skin Browser Preview" layout={"fullWidth"} aspectRatio={4/5}/>
        <StaticImage class="mb-6" src="../../images/projects/versed/mobile--03.jpeg" alt="Versed Skin Mobile Reviews" layout={"fullWidth"} aspectRatio={4/5}/>
      </div>
      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/versed/mobile--01.jpg" alt="Versed Skin Mobile Menu" layout={"fullWidth"}/>
        <StaticImage class="mb-20" src="../../images/projects/versed/mobile--02.jpg" alt="Versed Skin Mobile Menu" layout={"fullWidth"}/>
      </div>

      <div class="adv-gallery-v3 mb-20">
        <h4 className="color-contrast-higher mb-4 pl-8 lg:pl-0">Related</h4>
        <ul class="columns-1 lg:columns-2">
        <ProjectCard classes="" title="Relevant Skin" url="relevant-skin"  description="Science meets soul with a heaping dose of good vibes." cover={RelevantSkin} role="Development" />

        <ProjectCard classes="" title="Isa Lazo" url="isa-lazo"  description="Clean skincare crafted from the purest elements of nature." cover={IsaLazo}  role="Development"/>
        </ul>
      </div>
  </Layout>
)

export default aurisPage
